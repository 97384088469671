import React from "react";

const Contact = () => {
  return (
    <>
      <section class="contact" id="contact">
        <h1 class="heading">
          <span>تواصل</span> معنا
        </h1>
        <div class="row">
          <iframe
            class="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3709.363046808925!2d39.16541727514595!3d21.610772380188195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3d139f86d6d0d%3A0x4a397513057c5fd8!2z2YXYs9in2Kwg2LHZitmE2KfZg9izINiz2KrZiti02YY!5e0!3m2!1sen!2ssa!4v1683733884248!5m2!1sen!2ssa"
            // width="600"
            // height="450"
            // style="border:0;"
            allowfullscreen=""
            loading="lazy"
            // referrerpolicy="no-referrer-when-downgrade"
          ></iframe>

          <form action="">
            <h3>ابق على تواصل</h3>
            <div class="inputBox">
              <span class="fas fa-user"></span>
              <input type="text" placeholder="الإسم:" />
            </div>
            <div class="inputBox">
              <span class="fas fa-envelope"></span>
              <input type="email" placeholder="البريد الإلكتروني:" />
            </div>
            <div class="inputBox">
              <span class="fas fa-phone"></span>
              <input type="number" placeholder="رقم الجوال:" />
            </div>
            <input type="submit" value="إرسال" class="btn" />
          </form>
        </div>
      </section>
    </>
  );
};

export default Contact;
