import React from "react";

const Footer = () => {
  return (
    <>
      <section class="footer">
        <div class="share">
          {/* <a href="#" class="fab fa-google"></a> */}
          {/* <a href="https://twitter.com/adamsalonjeddah?lang=ar" class="fab fa-twitter"></a> */}
          <a href="https://www.instagram.com/relaxstation9/" class="fab fa-instagram"></a>
          {/* <a href="#" class="fab fa-snapchat"></a> */}
          <a href="https://www.tiktok.com/@relax_station2" class="fab fa-tiktok"></a>
        </div>
        <div class="links">
          <a href="#">الرئيسية</a>
          {/* <a href="#">about</a> */}
          {/* <a href="#">menu</a> */}
          {/* <a href="#">products</a> */}
          {/* <a href="#">review</a> */}
          <a href="#">خدماتنا</a>
          <a href="#">تواصل معنا</a>
        </div>
        <div class="credit">
          حقوق النشر محفوظة <span>ريلاكس ستيشن</span> 2024
        </div>
      </section>
    </>
  );
};

export default Footer;
