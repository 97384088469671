import React from "react";

const Home = () => {
  return (
    <>
      <section className="home" id="home">
        <div className="content">
          <h3>
            أفضل <span>مركز مساج </span>على الإطلاق في مدينة جدة
          </h3>
          <p>
            استمتع بأفضل تجربة استرخاء على الإطلاق، نحن نمنحك تجربة فريدة مع أخصائي العناية المحترفيين..
          </p>
          {/* <a href="https://wa.me/966535534097" className="btn"> */}
          <a href="https://wa.me/966535534097?text=ارغب%20بحجز%20موعد%20لديكم" className="btn">
            تواصل معنا الآن
          </a>
        </div>
      </section>
    </>
  );
};

export default Home;
