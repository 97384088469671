import React from "react";
import { blog } from "../Data";
const Blog = () => {
  return (
    <>
      <section class="blogs" id="blogs">
        <h1 class="heading">
           <span>خدماتنا</span>
        </h1>

        <div class="box-container">
          {blog.map((item, index) => (
            <div class="box" key={index * Math.random()}>
              <div class="image">
          
              
                <img src={item.img} alt="" />
              </div>
              <div class="content">
                <a href="#" class="title">
                {item.title}
                </a>
                <span>{item.price}</span>
                <p>نص تجريبي لشرح خدمات المساج بأنواعها وممزاتها</p>
                <a href={item.linok + item.title} class="btn">
                  اطلب الآن
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Blog;
