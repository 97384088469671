// import menuImage1 from "./assets/images/menu-1.png";
// import menuImage2 from "./assets/images/menu-2.png";
// import menuImage3 from "./assets/images/menu-3.png";
// import menuImage4 from "./assets/images/menu-4.png";
// import menuImage5 from "./assets/images/menu-5.png";
// import menuImage6 from "./assets/images/menu-6.png";
// import cartImage1 from "./assets/images/cart-item-1.png";
// import cartImage2 from "./assets/images/cart-item-2.png";
// import cartImage3 from "./assets/images/cart-item-3.png";
// import cartImage4 from "./assets/images/cart-item-4.png";
// import productImage1 from "./assets/images/product-1.png";
// import productImage2 from "./assets/images/product-3.png";
// import productImage3 from "./assets/images/product-3.png";
// import reviewImage1 from "./assets/images/pic-1.png";
// import reviewImage2 from "./assets/images/pic-1.png";
// import reviewImage3 from "./assets/images/pic-1.png";
// import blogImage1 from "./assets/images/blog-1.jpeg";
// import blogImage2 from "./assets/images/blog-2.jpeg";
// import blogImage3 from "./assets/images/blog-3.jpeg";

import blogImage1 from "./assets/images/service01.jpg";
import blogImage2 from "./assets/images/service02.jpg";
// import blogImage3 from "./assets/images/service03.jpg";

const menu = [
  {
    img: blogImage1,
    txt: "Hair cut",
    price: "35"
  },
  {
    img: blogImage1,
    txt: "Beard Shave",
    price: "30"
  },
  {
    img: blogImage1,
    txt: "Hair Pigment",
    price: "120"
  },
  {
    img: blogImage1,
    txt: "Normal Skin Cleansing",
    price: "80"
  },
  {
    img: blogImage1,
    txt: "Complete skin Cleansing",
    price: "180"
  },
  {
    img: blogImage1,
    txt: "Mask",
    price: "20"
  },
];


const cart = [
  {
    img: blogImage1,
  },
  {
    img: blogImage1,
  },
  {
    img: blogImage1,
  },
  {
    img: blogImage1,
  },
];

const product = [
  {
    img: blogImage1,
  },
  {
    img: blogImage1,
  },
  {
    img: blogImage1,
  },
];
const review = [
  {
    img: blogImage1,
  },
  {
    img: blogImage1,
  },
  {
    img: blogImage1,
  },
];
const blog = [
  {
    title: "مساج سويدي",
    price: "150 ريال",
    img: blogImage1,
    linok: "https://wa.me/966535534097?text=ارغب%20بحجز%20موعد%20لخدمة%20"
  },
  {
    title: "مساج شياتسو",
    price: "150 ريال",
    img: blogImage2,
    linok: "https://wa.me/966535534097?text=ارغب%20بحجز%20موعد%20لخدمة%20"
  },
  {
    title: "مساج مكس ( سويدي + شياتسو )",
    price: "150 ريال",
    img: blogImage2,
    linok: "https://wa.me/966535534097?text=ارغب%20بحجز%20موعد%20لديكم"
  },
  {
    title: "مساج تايلندي",
    price: "200 ريال",
    img: blogImage2,
    linok: "https://wa.me/966535534097?text=ارغب%20بحجز%20موعد%20لخدمة%20"
  },
  {
    title: "مساح بالأحجار الساخنة",
    price: "200 ريال",
    img: blogImage2,
    linok: "https://wa.me/966535534097?text=ارغب%20بحجز%20موعد%20لخدمة%20"
  },
  {
    title: "مساج مكس ( تايلندي + بالأحجار الساخنة )",
    price: "250 ريال",
    img: blogImage2,
    linok: "https://wa.me/966535534097?text=ارغب%20بحجز%20موعد%20لخدمة%20"
  },
  {
    title: "حمام مغربي",
    price: "150 ريال",
    img: blogImage2,
    linok: "https://wa.me/966535534097?text=ارغب%20بحجز%20موعد%20لخدمة%20"
  },
  {
    title: "حمام مغربي ملكي",
    price: "200 ريال",
    img: blogImage2,
    linok: "https://wa.me/966535534097?text=ارغب%20بحجز%20موعد%20لخدمة%20"
  },
  {
    title: "بدي كير",
    price: "100 ريال",
    img: blogImage2,
    linok: "https://wa.me/966535534097?text=ارغب%20بحجز%20موعد%20لخدمة%20"
  },
  {
    title: "تنظيف بشرة وعناية بالوجه",
    price: "100 ريال",
    img: blogImage2,
    linok: "https://wa.me/966535534097?text=ارغب%20بحجز%20موعد%20لخدمة%20"
  }
];
export { menu, cart, product, review, blog};
